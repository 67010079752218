<template>
  <div
    class="content text-center mx-auto d-flex justify-content-center align-items-center"
  >
    <div class="body-content">
      <div
        class="d-flex flex-column justify-content-center align-items-center title-content"
      >
        <p class="top-head font-weight-bold fs-16 mb-0">
          {{ thanksPageHeader.replace('{customerName}', customerName) }}
        </p>
        <div
          class="d-flex flex-column justify-content-center align-items-center desc"
        >
          <p class="desc-content font-weight-thin fs-14 mb-0">
            You will receive a confirmation email with a summary of the updates
            made.
          </p>
        </div>
      </div>
      <div
        class="bg-white d-flex flex-column align-items-center thanks-page-body"
      >
        <div class="bottom-first-content">
          <p class="bottom-head font-weight-bold fs-16 mt-3">
            What to expect next?
          </p>
          <ul>
            <li
              v-for="(item, index) in whatToExpectNext.text"
              :key="index"
              class="font-weight-thin"
            >
              {{ item }}
            </li>
            <li v-html="whatToExpectNextContent"></li>
          </ul>
        </div>
        <div class="bottom-second-content">
          <p class="bottom-head font-weight-bold fs-16">
            Pick up where you left off
          </p>
          <p class="bottom-desc-content font-weight-regular fs-18">
            <b>Changes can take a few minutes to update. </b>
            Update your car details and add more photos at any time.
          </p>
          <a
            :href="getMyCarLink"
            class="update-photo-btn"
            >Update details</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MAIN_PAGE_URL } from "@/helper/constant";
import { mapState } from "vuex";

export default {
  name: "Index",
  data() {
    return {
      customerName: this.$store.getters.customerName,
      isMobile: true,
      mainPageUrl: MAIN_PAGE_URL,
    };
  },
  computed: {
    ...mapState({
      footerLinks: (state) => state.commonVariables.footer,
      whatToExpectNext: (state) => state.commonVariables.copyBlocks.whatToExpect,
      thanksPageHeader: (state) => state.commonVariables.copyBlocks.thanksPageHeader,
      authenticateData: (state) => state.authenticateSeller,
    }),
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 768px)");
    },
    previousQueryString: function() {
      const queryObject = JSON.parse(window.localStorage.getItem('universal-qs'));
      delete queryObject.generated_time
      return new URLSearchParams(queryObject).toString()
    },
    whatToExpectNextContent() {
      let result = this.whatToExpectNext.textLink;
      for(const item of this.whatToExpectNext.links) {
        result = result.replace(
          `{${item.name}}`,
          `<a
            style="color: #000000;"
            href="${item.link}"
            target="_blank"
            ><u>${item.name}</u></a
          >`
        );
      }

      return result;
    },
    isNZ: function() {
      return parseInt(this.authenticateData.locid) === 2;
    },
    getMyCarLink() {
      return this.isNZ ? `https://mycar.autoflip.co.nz/?${this.previousQueryString}` : `https://mycar.autoflip.com.au/?${this.previousQueryString}`;
    },
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>

<style lang="scss">
.content {
  color: #404040;
  min-height: calc(100vh -  75px - 235px);
  
  .body-content {
    margin-bottom: 30px;
    min-height: calc(100vh - 75px - 235px - 30px);

    .title-content {
      background-color: #F9FAFB;
      padding: 35px 15px;

      .top-head {
        color: #414141;
        font-size: 36px;
        line-height: 44px;
      }

      .desc {
        .desc-content {
          color: #4F4F4F;
          font-size: 18px;
          line-height: 28px;
          margin-top: 20px;
        }
      }
    }

    .bottom-head {
      color: var(--primary-color);
      font-size: 32px;
      line-height: 40px;
    }

    .bottom-first-content {
      a {
        color: #000000;
      }
    }
    
    .bottom-second-content {
      background: #F9FAFB;
      margin-top: 20px;
      padding: 5px 5px 15px 5px;
    }
    .bottom-desc-content {
      font-weight: 500 !important;
      line-height: 28px;
      color: #414141;
      margin-bottom: 30px;
    }

    .thanks-page-body {
      min-height: calc(100vh - 255px - 60px - 60px - 216px);
      text-align: left;
      padding: 15px 15px 15px 20px;
      margin-bottom: 25px;
      align-items: flex-start !important;
      ul {
        padding-left: 30px;
        margin-bottom: unset;
        li {
          font-family: Avenir Next LT W05 Thin !important;
          font-size: 18px;
          line-height: 28px;
          color: #414141;
          font-weight: 600;
        }
      }
    }

    .update-photo-btn {
      padding: 11px 24px;
      position: relative;
      color: var(--primary-color);
      background-color: #FFFFFF;
      border: 1px solid var(--primary-color);
      border-radius: 6px;
      font-family: Avenir Next LT W05 Regular !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .container {
    padding: unset !important;
    max-width: unset !important;
    min-height: unset;
  }
  .content {
    // min-height: calc(100vh - 225px);
    .body-content {
      // min-height: calc(100vh - 75px - 120px - 30px);
      width: 100%;
    }
  }
  .title-content {
    padding: 40px 40px !important;

    .top-head {
      line-height: 48px !important;
    }
    .desc {
      margin-top: 8px !important;
      .desc-content {
        font-size: inherit !important;
        margin-top: 0 !important;
      }
    }
  }
  .thanks-page-body {
    width: 784px;
    margin: 0 auto;
    padding: unset !important;
  }
  .bottom-head {
    line-height: 44px !important;
    max-width: unset !important;
    margin-top: unset !important;
  }
  .bottom-first-content {
    margin-top: 30px;
    padding: 10px 20px 20px 20px !important;
  }
  .bottom-second-content {
    margin-top: unset !important;
    padding: 10px 20px 20px 20px !important;
  }
}
</style>
